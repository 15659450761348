import { Component, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { SymptomService } from '../../services/symptom.service';
import { Symptom } from '../../classes/symptom';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../classes/user';
import { SymptomFilterComponent } from '../../modals/symptom-filter/symptom-filter.component';
import { ModalService } from '@dc/ui/components/modal/modal.service';


@Component({
  selector: 'main [dc-symptom-list]',
  templateUrl: './symptom-list.component.html'
})
export class SymptomListComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  user: User = null;
  symptoms: Symptom[] = [];
  searchTerm: string = '';
  sort: any = {
    field: 'id',
    direction: 'asc'
  };
  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 15
  };
  filters: {
    region: any
  } = {
    region: null
  };

  loadingSymptoms: boolean = false;

  constructor(
    private symptomService: SymptomService,
    private router: Router,
    private modalService: ModalService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.region) { this.filters.region = queryParams.region}
      this.getSymptoms();
    });
  }

  sortSymptoms(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }

    this.getSymptoms();
  }

  getSymptoms(append: boolean = false) {
    if (append && this.pagination.total && this.symptoms.length >= this.pagination.total) { return; }

    this.pagination.current_page = append ? this.pagination.current_page + 1 : 1;

    const params: any = {
      current_page: this.pagination.current_page,
      per_page: this.pagination.per_page,
      sort: this.sort.field,
      direction: this.sort.direction,
      pagination: true
    };
    if (this.searchTerm.length > 0) {
      params.search_term = this.searchTerm;
    }
    Object.keys(this.filters).map((key) => {
      if (this.filters[key]) {
        params[key] = this.filters[key];
      }
    });
    this.loadingSymptoms = !append;
    this.symptomService
      .getSymptoms(params)
      .subscribe(
        (response: { data: Symptom[], total: number }) => {
          if (append) {
            this.symptoms = this.symptoms.concat(response.data);
          } else {
            this.perfectScrollbarDirectiveRef.scrollToTop();
            this.symptoms = response.data;
            this.pagination.total = response.total;
          }
          this.loadingSymptoms = false;
        },
        (error) => {
          this.loadingSymptoms = false;
        }
      );
  }

  showFilterModal() {
    this.checkFilters();
    const modalRef = this.modalService.open(SymptomFilterComponent);
    modalRef.componentInstance.filters = this.filters;
    modalRef.result.then(
      (filters) => {
        this.filters = filters;
        this.router.navigate([], {queryParams: this.filters, queryParamsHandling: 'merge', relativeTo: this.route})
      },
      () => {}
    );
  }

  checkFilters() {
    if (this.route.snapshot.queryParams.region) { this.filters.region = this.route.snapshot.queryParams.region; }
  }

}
