<section class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}">
    <div [dcLoading]="loadingUser"></div>
    <div class="dc-container">
        <div class="dc-row middle">
          <div class="dc-col sm-12">
            <h1>
                <div class="super-heading" translate>Profile</div>
                <span *ngIf="user">{{ user.first_name + ' ' + user.last_name }}</span>
            </h1>
          </div>
        </div>
        <div class="dc-row space-12">
            <div class="dc-col md-12">
                <div class="dc-row">
                    <div class="dc-col md-12">
                        <h2 translate>Information</h2>
                    </div>
                </div>
            </div>
            <div class="dc-col md-3">
                <form [formGroup]="userForm">
                    <div class="dc-row">
                        <div class="dc-col md-6">
                            <dc-image
                                icon="profile"
                                formControlName="photo_url"
                                [label]="'Photo' | translate"
                            ></dc-image>
                        </div>
                    </div>
                </form>
            </div>
            <div class="dc-col md-3">
                <form [formGroup]="userForm">
                    <div class="dc-row">
                        <div class="dc-col md-12">
                            <dc-input
                                label="{{ 'First name' | translate }}"
                                formControlName="first_name"
                            ></dc-input>
                        </div>
                        <div class="dc-col md-12">
                            <dc-input
                                label="{{ 'Last name' | translate }}"
                                formControlName="last_name"
                            ></dc-input>
                        </div>
                    </div>
                </form>
            </div>
            <div class="dc-col md-3">
                <form [formGroup]="passwordForm">
                    <div class="dc-row">
                        <div class="dc-col md-12">
                            <dc-input
                                label="{{ 'New password' | translate }}"
                                formControlName="password"
                                type="password"
                                [errorMessages]="{ required:'Required' | translate }"
                            ></dc-input>
                        </div>
                        <div class="dc-col md-12">
                            <dc-input
                                label="{{ 'Confirm new password' | translate }}"
                                formControlName="confirm"
                                type="password"
                                [errorMessages]="{ required:'Required' | translate }"
                            ></dc-input>
                        </div>
                        <div class="dc-col md-12">
                            <dc-button (click)="changePassword()" [loading]="changingPassword" [disabled]="passwordForm.invalid">{{ 'Change password' | translate }}</dc-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<div class="dc-bottom-bar">
    <div class="dc-container flex" style="height: 100%; align-items: center;">
        <dc-button size="small" color="secondary" (click)="close()">{{ 'Close' | translate }}</dc-button>
        &nbsp;&nbsp;
        <dc-button size="small" (click)="saveUser()" [loading]="savingUser" [disabled]="userForm.invalid">{{ 'Save' | translate }}</dc-button>
    </div>
</div>
