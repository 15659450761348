import { Component, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { UiContentService } from '../../services/ui-content.service';
import { UiContent } from '../../classes/ui-content';
import { ModalService } from '@dc/ui/components/modal/modal.service';
import { UiContentTranslateComponent } from '../../modals/ui-content-translate/ui-content-translate.component';


@Component({
  selector: 'main[dc-ui-translations]',
  templateUrl: './ui-content.component.html'
})
export class UiContentComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  uiContents: UiContent[] = [];
  uiContent: UiContent;
  searchTerm: string = '';
  uiContentId: number;
  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 15
  };

  loadingUiContents: boolean = false;

  constructor(
    private uiContentService: UiContentService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.getUiContents();
  }

  getUiContents(append: boolean = false) {
    if (append && this.pagination.total && this.uiContents.length >= this.pagination.total) { return; }

    this.pagination.current_page = append ? this.pagination.current_page + 1 : 1;

    const params: any = {
      current_page: this.pagination.current_page,
      per_page: this.pagination.per_page,
      include: 'translations.language'
    };
    if (this.searchTerm.length > 0) {
      params.search_term = this.searchTerm;
    }
    this.loadingUiContents = !append;
    this.uiContentService
    .getUiContents(params)
    .subscribe(
      (response: { data: UiContent[], total: number }) => {
        if (append) {
          this.uiContents = this.uiContents.concat(response.data);
        } else {
          this.perfectScrollbarDirectiveRef.scrollToTop();
          this.uiContents = response.data;
          this.pagination.total = response.total;
        }
        this.loadingUiContents = false;
    },
      (error) => {
        this.loadingUiContents = false;
      }
    );
  }

  getUiContent() {
    this.uiContentService
      .currentUiContent
      .subscribe(
        (uiContent: UiContent) => {
          if (uiContent) {
            this.uiContent = uiContent;
            this.getUiContent();
          }
        },
        () => { }
      );
  }

  translateUiContent(uiContentId: number) {
    const modalRef = this.modalService.open(UiContentTranslateComponent);
    modalRef.componentInstance.uiContentId = uiContentId;
    modalRef.result.then(
      (result) => {
       if (result) {
         this.getUiContents();
       }
      },
      () => { }
    );
  }

}
