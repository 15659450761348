<div class="dc-modal small">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">{{ 'Filters' | translate }}</h2>
    <span class="dc-modal__close" tabindex="0" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [dcLoading]="showLoading">
    <div class="dc-row">
      <div class="dc-col">
        <dc-select label="{{'Region' | translate}}" [options]="regions" [(ngModel)]="filters.region" placeholder="{{'Select region' | translate}}"></dc-select>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="removeFilters()">{{ 'Remove filters' | translate }}</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
      <dc-button (click)="apply()">{{ 'Apply' | translate }}</dc-button>
    </div>
  </div>
</div>
