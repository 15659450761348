import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './services/user.service';
import { User } from './classes/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Admin';
  user: User = null;

  showNav: boolean = false;
  showProfileNav: boolean = false;

  constructor(
    translate: TranslateService,
    private authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    userService: UserService
  ) {
    translate.setDefaultLang('en');
    translate.use('rs');
    userService.currentUser
      .subscribe(user => this.user = user );
    if (localStorage.getItem('jwt')) {
      userService.getCurrentUser();
    }
  }

  logout() {
    this.authService.logout()
      .subscribe(
        response => {
          this.router.navigate(['./login']);
        }
      );
  }

  toggleNav() {
    if (this.showProfileNav) { this.showProfileNav = false; }
    this.showNav = !this.showNav;
  }

  toggleProfileNav() {
    if (this.showNav) { this.showNav = false; }
    this.showProfileNav = !this.showProfileNav;
  }

}

