<div class="public">
    <img class="logo" src="assets/images/logo-white.svg" alt="Doktorijum">

    <div class="form">
        <form [formGroup]="changePasswordRequestForm">
            <h2 translate>Change password</h2>
            <div class="dc-row">
                <div class="dc-col">
                    <div class="text-center">
                        <p translate>
                            Enter your email (username) to send you password change instructions
                        </p>
                    </div>
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        formControlName="email"
                        [label]="'Email (username)' | translate"
                        [errorMessages]="{ required:'Required' | translate }"
                    ></dc-input>
                </div>
            </div>
            <div class="dc-row space-16">
                <div class="dc-col sm-6">
                    <dc-button size="full" [routerLink]="['/login']">{{ 'Cancel' | translate }}</dc-button>
                </div>
                <div class="dc-col sm-6">
                    <dc-button size="full" (click)="sendRequest()" [loading]="sendingRequest" [disabled]="changePasswordRequestForm.invalid">{{ 'Send request' | translate }}</dc-button>
                </div>
            </div>
        </form>
    </div>
</div>
