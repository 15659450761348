import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  constructor(
    private apiService: ApiService
  ) { }

  getInstitutions( params: any = {} ) {
    return this.apiService.get(`institution`, params);
  }

  getInstitution(institutionId: any, params: any = {}) {
    return this.apiService.get(`institution/${institutionId}`, params);
  }

  createInstitution(params: any = {}) {
    return this.apiService.post(`institution`, params);
  }

  updateInstitution(institutionId: number, params: any = {}) {
    return this.apiService.put(`institution/${institutionId}`, params);
  }

  deleteInstitution(institutionId: number) {
    return this.apiService.delete(`institution/${institutionId}` );
  }

  getCities(params: any = {}) {
    return this.apiService.get(`city`, params);
  }

  createCity(params: any = {}) {
    return this.apiService.post(`city`, params)
  }
}
