<div class="dc-subheader" >
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col xs-6">
          <ul class="navigation">
            <li>
              <a [routerLink]="['./specialty', 'list']" [class.active]="router.url.includes('specialty')"  translate>Specialties</a>
            </li>
            <li>
              <a [routerLink]="['./symptom', 'list']" [class.active]="router.url.includes('symptom')"  translate>Symptoms</a>
            </li>
            <li>
              <a [routerLink]="['./translations']" [class.active]="router.url.includes('translations')" translate>Languages</a>
            </li>
          </ul>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
