import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Specialty } from '../classes/specialty';

@Injectable({
  providedIn: 'root'
})
export class SpecialtyService {

  constructor(
    private apiService: ApiService
  ) { }

  getSpecialties(query: any = {} ) {
    return this.apiService.get('specialty', query);
  }

  getSpecialty(specialtyId: any, query) {
    return this.apiService.get(`specialty/${specialtyId}`, query);
  }
  createSpecialty(specialty: Specialty) {
    return this.apiService.post(`specialty`, specialty );
  }

  updateSpecialty(specialtyId: number, specialty: Specialty) {
    return this.apiService.put(`specialty/${specialtyId}`, specialty );
  }

  deleteSpecialty(specialtyId: number) {
    return this.apiService.delete(`specialty/${specialtyId}` );
  }
}
