import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCFileComponent } from './file.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DCProgressBarModule } from '../progress-bar/progress-bar.module';
import { DCAutoUploadModule } from './_modals/auto-upload/auto-upload.module';



@NgModule({
  declarations: [
    DCFileComponent
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    FormsModule,
    PerfectScrollbarModule,
    DCProgressBarModule,
    DCAutoUploadModule
  ],
  providers: [
    NgbModal,
    NgbActiveModal
  ],
  exports: [
    DCFileComponent
  ]
})
export class DCFileModule { }
