<div class="public">
    <img class="logo" src="/assets/images/logo-white.svg" alt="Doktorijum">

    <div class="form">
        <form [formGroup]="loginForm">
            <h2 translate>Login</h2>

            <div class="message error" *ngIf="error">
                <div class="text" translate>{{ error }}</div>
                <div class="icon close" (click)="clearError()"></div>
            </div>

            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        formControlName="email"
                        [label]="'Email (username)' | translate"
                        autoFocus
                        [errorMessages]="{ required:'Required' | translate }"
                    ></dc-input>
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        type="password"
                        formControlName="password"
                        [label]="'Password' | translate"
                        [errorMessages]="{ required:'Required' | translate }"
                    ></dc-input>
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col">
                    <dc-button size="full" (click)="login()" [loading]="loggingIn" [disabled]="loginForm.invalid">{{ 'Login' | translate }}</dc-button>
                </div>
            </div>
        </form>
        <div>
            <div class="forgot-password">
                <a [routerLink]="['/change-password/request']" translate>Forgot password</a>
            </div>
        </div>
    </div>
</div>
