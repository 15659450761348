import { Component, OnInit, ViewChild } from '@angular/core';
import { Specialty } from '../../classes/specialty';
import { SpecialtyService } from '../../services/specialty.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { User } from '../../classes/user';


@Component({
  selector: 'main [dc-specialty-list]',
  templateUrl: './specialty-list.component.html'
})
export class SpecialtyListComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  user: User = null;
  specialties: Specialty[] = [];
  searchTerm: string = '';
  sort: any = {
    field: 'id',
    direction: 'asc'
  };
  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 15
  };

  loadingSpecialties: boolean = false;

  constructor(
    private specialtyService: SpecialtyService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getSpecialties();
  }

  sortSpecialties(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }

    this.getSpecialties();
  }

  getSpecialties(append: boolean = false) {
    if (append && this.pagination.total && this.specialties.length >= this.pagination.total) { return; }

    this.pagination.current_page = append ? this.pagination.current_page + 1 : 1;

    const params: any = {
      current_page: this.pagination.current_page,
      per_page: this.pagination.per_page,
      sort: this.sort.field,
      direction: this.sort.direction
    };
    if (this.searchTerm.length > 0) {
      params.search_term = this.searchTerm;
    }
    this.loadingSpecialties = !append;
    this.specialtyService
      .getSpecialties(params)
      .subscribe(
        (response: { data: Specialty[], total: number }) => {
          if (append) {
            this.specialties = this.specialties.concat(response.data);
          } else {
            this.perfectScrollbarDirectiveRef.scrollToTop();
            this.specialties = response.data;
            this.pagination.total = response.total;
          }
          this.loadingSpecialties = false;
        },
        (error) => {
          this.loadingSpecialties = false;
        }
      );
  }

}
