import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCAutoUploadComponent } from './auto-upload.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DCProgressBarModule } from '../../../progress-bar/progress-bar.module';



@NgModule({
  declarations: [DCAutoUploadComponent],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    DCProgressBarModule
  ],
  providers: [
    NgbModal,
    NgbActiveModal
  ],
  exports: [DCAutoUploadComponent]
})
export class DCAutoUploadModule { }
