<div class="dc-modal medium">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">{{ 'Translate' | translate }}</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" perfectScrollbar>
    <div [dcLoading]="showLoading"></div>
    <div class="dc-row space-16">
      <div class="dc-col md-12" *ngFor="let lang of uiContentTranslations; let i = index">
        <dc-input
          type="textarea"
          label="{{ lang.name | translate }}"
          [(ngModel)]="uiContentTranslations[i].value"
        ></dc-input>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button  (click)="close()">{{ 'Cancel' | translate }}</dc-button>
      <dc-button *ngIf="uiContentId"  (click)="delete()" [loading]="deletingUiContent">{{ 'Delete' | translate }}</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button (click)="save(true)" [loading]="savingUiContent">{{ 'Save' | translate }}</dc-button>
    </div>
  </div>
</div>

