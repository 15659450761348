import {
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import CKEDITOR from './assets/ckeditor/ckeditor';

@Component({
  selector: 'dc-text-editor',
  templateUrl: './text-editor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DCTextEditorComponent),
      multi: true
    }
  ]
})
export class DCTextEditorComponent implements OnInit, ControlValueAccessor {

  @Input() value: string = null;
  @Input() name: string;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() requiredType: 'default' | 'full' = 'default' ;
  @Input() formControlName: string;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() type: string | 'default' | 'standard-edit' | 'restricted-edit' = 'default';
  @Input() errorMessages: any = {};

  @Output() ready = new EventEmitter();

  public editor;

  config: any;
  errors: any[] = [];
  showErrors: boolean = false;
  focused: boolean = false;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;



  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) { }

  ngOnInit(): void {
    this.setImports();
    if (this.disabled !== false) { this.disabled = true; }
    if (this.required !== false) { this.required = true; }
    this.config = {
      placeholder: this.placeholder
    };
    this.checkRequired();
  }

  setImports() {
    if (this.type === 'default') {
      this.editor = CKEDITOR.DecoupledEditor;
    } else if (this.type === 'standard-edit') {
      this.editor = CKEDITOR.DecoupledEditorStandardEdit;
    } else if (this.type === 'restricted-edit') {
      this.editor = CKEDITOR.DecoupledEditorRestricted;
    }
  }

  writeValue(value: any): void {
    this.value = value ? value : '';
    if (!this.value && this.value.length === 0) {
      this.errors = [];
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChangeValue() {
    if (this.onChange) {
      this.onChange(this.value);
      this.displayError();
    }
  }

  checkRequired() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.validator) {
      const validators = formControl.validator({}as AbstractControl);
      if (validators && validators.required) {
        this.required = true;
      }
    }
  }

  displayError() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.errors) {
      const errors = [];

      for (const [key, value] of Object.entries(formControl.errors)) {
        errors.push(key);
      }

      this.errors = errors;
    } else {
      this.errors = [];
    }
  }

  toggleErrors() {
    this.showErrors = !this.showErrors;
  }

  toggleOnActive() {
    this.focused = !this.focused;
  }

  onReady(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader)=> {
      return new UploadAdapter(loader);
    };
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

}

class UploadAdapter {
  loader: any;
  constructor( loader ) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file
      .then( file => new Promise( ( resolve, reject ) => {
        var myReader= new FileReader();
        myReader.onloadend = (e) => {
          resolve({ default: myReader.result });
        }

        myReader.readAsDataURL(file);
      } ) );
  };
}
