import { Doctor } from 'apps/patient/src/app/_classes/doctor';

export class Specialty {
  id: number;
  name: string;
  color: string;

  institutionServices?: any[];
  doctors?: Doctor[];

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
