import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { InstitutionListComponent } from './pages/institution-list/institution-list.component';
import { InstitutionDetailsComponent } from './pages/institution-details/institution-details.component';
import { SymptomListComponent } from './pages/symptom-list/symptom-list.component';
import { SymptomDetailsComponent } from './pages/symptom-details/symptom-details.component';
import { SpecialtyListComponent } from './pages/specialty-list/specialty-list.component';
import { AuthGuard } from './guards/auth.guard';
import { SpecialtyDetailsComponent } from './pages/specialty-details/specialty-details.component';
import { LoginComponent } from './pages/login/login.component';
import { ChangePasswordRequestComponent } from './pages/change-password-request/change-password-request.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ProfileComponent } from './pages/profile/profile.component';
import {UiContentComponent} from './pages/ui-content/ui-content.component';
import {SettingsComponent} from './pages/settings/settings.component';



const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    children: [],
  },
  {
    path: 'change-password/request',
    component: ChangePasswordRequestComponent,
    children: []
  },
  {
    path: 'change-password/token/:token',
    component: ChangePasswordComponent,
    children: []
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [],
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'institution/list',
    component: InstitutionListComponent,
    children: [],
    canActivate: [AuthGuard]
  },
  {
    path: 'institution/new',
    component: InstitutionDetailsComponent,
    children: [],
    canActivate: [AuthGuard]
  },
  {
    path: 'institution/:institutionId/details',
    component: InstitutionDetailsComponent,
    children: [],
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    children: [],
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    children: [
      {
        path: '',
        redirectTo: 'specialty/list',
        pathMatch: 'full'
      },
      {
        path: 'specialty/list',
        component: SpecialtyListComponent,
        children: [],
        canActivate: [AuthGuard]
      },
      {
        path: 'specialty/new',
        component: SpecialtyDetailsComponent,
        children: [],
        canActivate: [AuthGuard]
      },
      {
        path: 'specialty/:specialtyId/details',
        component: SpecialtyDetailsComponent,
        children: [],
        canActivate: [AuthGuard]
      },
      {
        path: 'symptom/list',
        component: SymptomListComponent,
        children: [],
        canActivate: [AuthGuard]
      },
      {
        path: 'symptom/new',
        component: SymptomDetailsComponent,
        children: [],
        canActivate: [AuthGuard]
      },
      {
        path: 'symptom/:symptomId/details',
        component: SymptomDetailsComponent,
        children: [],
        canActivate: [AuthGuard]
      },
      {
        path: 'translations',
        component: UiContentComponent,
        children: [],
        canActivate: [AuthGuard]
      }
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
