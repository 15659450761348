import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Specialty } from '../../classes/specialty';
import { SpecialtyService } from '../../services/specialty.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { ModalService } from '@dc/ui/components/modal/modal.service';
import { User } from '../../classes/user';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'main [dc-specialty-details]',
  templateUrl: './specialty-details.component.html'
})
export class SpecialtyDetailsComponent implements OnInit, OnDestroy {
  fixedSign: string = '#';
  user: User = null;
  specialtyForm: FormGroup;
  specialty: Specialty = new Specialty();
  specialtyId: number;
  specialties: Specialty[] = [];
  showLoading: boolean = false;
  subscriptions: Subscription[] = [];
  savingSpecialty: boolean = false;
  deletingSpecialty: boolean = false;
  colorValue: string;
  constructor(
    private formBuilder: FormBuilder,
    private specialtyService: SpecialtyService,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: DCToastService,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getColorChanges();
    const routeSubscription = this.route.params.subscribe(
      (data) => {
        if (data.specialtyId) {
          this.specialtyId = +data.specialtyId;
          this.getSpecialty();
        }
      }
    );
    this.subscriptions.push(routeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  initForm() {
    this.specialtyForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        color: ['', [Validators.minLength(4), Validators.required]],
      }
    );
  }

  fixingSign(event) {
    if(event.target.value.length == 1 && (event.code == "Backspace" || event.code == "Delete")) {
      return false;
    }
      return true;
  }

  getColorChanges() {
    const color = this.specialtyForm.get('color');
    color.valueChanges.subscribe(
      (response) => {
        this.colorValue = response;
      },
      () => {},
    )
  }

  getSpecialty() {
    if (this.specialtyId) {
      this.showLoading = true;
      const params = {
      }
      this.specialtyService.getSpecialty(this.specialtyId, params)
        .subscribe(
          (specialty) => {
            this.specialtyForm.patchValue(specialty.data);
            this.specialty = specialty.data;
            this.showLoading = false;
          },
          () => {
            this.showLoading = false;
          }
        );
    }
  }

  save(close: boolean = false) {
    if (this.specialtyForm.invalid) { return; }

    if (this.specialtyId) {
      this.savingSpecialty = true;
      this.specialtyService
        .updateSpecialty(this.specialtyId, this.specialtyForm.value)
        .subscribe(
          (response: any) => {
            this.toasterService.show({
              message: this.translate.instant('Specialty was successfully updated.'),
              type: 'success'
            });
            this.savingSpecialty = false;
            if (close) { this.close(); } else {
              this.router.navigate(['./settings' + '/specialty/' + this.specialtyId + '/details']);
            }
          },
          () => {
            this.toasterService.show({
              message: this.translate.instant('There was an error while saving the specialty.'),
              type: 'error'
            });
            this.savingSpecialty = false;
          }
        );
    } else {
      this.savingSpecialty = true;
      this.specialtyService
        .createSpecialty(this.specialtyForm.value)
        .subscribe(
          (response: any ) => {
            this.toasterService.show({
              message: this.translate.instant('Specialty was successfully created.'),
              type: 'success'
            });
            this.specialtyId = response.id;
            this.savingSpecialty = false;

            if (close) {
              this.close();
            } else {
              this.router.navigate(['./settings' + '/specialty/' + this.specialtyId + '/details']);
            }
          },
          () => {
            this.toasterService.show({
              message:  this.translate.instant('There was an error while saving the specialty.'),
              type: 'error'
            });
            this.savingSpecialty = false;
          }
        );
    }
  }

  delete() {
    if (!this.specialtyId) { return; }

    this.modalService.confirm({
      title: this.translate.instant('Delete'),
      body: this.translate.instant('Are you sure you want to delete this specialty?'),
      size: 'small',
      buttons: [
        {
          text: this.translate.instant('No'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('Yes'),
          handler: () => {
            this.deletingSpecialty = true;
            this.specialtyService
              .deleteSpecialty(this.specialtyId)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: this.translate.instant('Specialty successfully deleted.'),
                    type: 'success'
                  });
                  this.deletingSpecialty = false;
                  this.close();
                },
                () => {
                  this.toasterService.show({
                    message: this.translate.instant('Failed to delete the specialty.'),
                    type: 'error'
                  });
                  this.deletingSpecialty = false;
                }
              );
          }
        }
      ]
    });
  }


  close() {
    this.router.navigate(['settings', 'specialty', 'list']);
  }
}
