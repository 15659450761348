<section class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}">
  <div [dcLoading]="showLoading"></div>
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col sm-6">
        <h1>
          <div class="super-heading" translate>Institution</div>
          <span>{{ institution.name }}</span>
        </h1>
      </div>
      <div class="dc-col sm-6 flex padding-bottom" style="flex-direction: row; justify-content: flex-end;">
        <dc-button *ngIf="institutionId" color="outline" icon="delete" size="small" [loading]="deletingInstitution" (click)="delete()">{{ 'Delete institution' | translate }}</dc-button>
      </div>
    </div>
    <form class="dc-row space-16" [formGroup]="institutionForm">
      <div class="dc-col md-12">
        <div class="dc-row space-16">
          <div class="dc-col md-12">
            <h2 class="dc-text-color-primary" translate>Information</h2>
          </div>
          <div class="dc-col md-2">
            <dc-image
              formControlName="logo_url"
              [label]="'Logo' | translate"
            ></dc-image>
          </div>
          <div class="dc-col md-4">
            <dc-input
              label="{{ 'Institution name' | translate }}"
              formControlName="name"
              [errorMessages]="{ required:'Required' | translate }"
            ></dc-input>
          </div>
          <div class="dc-col md-6">
            <dc-select
              label="{{ 'Specialties' | translate }}"
              formControlName="specialty_ids"
              [options]="specialties"
              [multi]="true"
              [errorMessages]="{ required:'Required' | translate }"
            ></dc-select>
          </div>
        </div>
      </div>
      <div class="dc-col md-6">
        <div class="dc-row space-12">
          <div class="dc-col md-12">
            <h2 class="dc-text-color-primary" translate>Location</h2>
          </div>
          <div class="dc-col md-6">
            <dc-select
              label="{{ 'Country' | translate }}"
              formControlName="country_id"
              [options]="countries"
              [errorMessages]="{ required:'Required' | translate }"
            ></dc-select>
          </div>
          <div class="dc-col md-6">
            <dc-select
              *ngIf="showCity && this.institutionForm.get('country_id').value"
              (addButtonClick)="showAddCityModal()"
              [addButtonText]="'Add' | translate"
              [showAddButton]="!cities || cities.length === 0"
              [showSearch]="true"
              (searched)="getCities($event)"
              label="{{ 'City' | translate }}"
              formControlName="city_id"
              [options]="cities"
              [errorMessages]="{ required:'Required' | translate }"
            ></dc-select>
          </div>
          <div class="dc-col md-12">
            <dc-input
              label="{{ 'Address' | translate }}"
              formControlName="address"
              [errorMessages]="{ required:'Required' | translate }"
            ></dc-input>
          </div>
          <div class="dc-col md-12 padding-top" *ngIf="institution.longitude && institution.latitude">
            <dc-map
              [center]="[ institution.longitude, institution.latitude ]"
              [markerCenter]="[ institution.longitude, institution.latitude ]"
            ></dc-map>
          </div>
          <div class="dc-col md-12">
            <h2 class="dc-text-color-primary" translate>Transportation</h2>
          </div>
          <div class="dc-col md-6 transport">
            <div class="dc-row">
              <div class="dc-col md-2">
                <span class="dc-icon bus"></span>
              </div>
              <div class="dc-col md-10">
                <dc-input
                  formControlName="transportation_bus"
                ></dc-input>
              </div>
              <div class="dc-col md-2">
                <span class="dc-icon subway"></span>
              </div>
              <div class="dc-col md-10">
                <dc-input
                  formControlName="transportation_tram"
                ></dc-input>
              </div>
              <div class="dc-col md-2">
                <span class="dc-icon trolley"></span>
              </div>
              <div class="dc-col md-10">
                <dc-input
                  formControlName="transportation_trolley"
                ></dc-input>
              </div>
            </div>
          </div>
          <div class="dc-col md-6 transport">
            <div class="dc-row">
              <div class="dc-col md-2">
                <span class="dc-icon parking"></span>
              </div>
              <div class="dc-col md-10">
                <div class="padding-vertical">
                  <dc-switch
                    style="justify-content: space-between;"
                    label="{{ 'Parking available' | translate }}"
                    formControlName="parking_available"
                  ></dc-switch>
                </div>
              </div>
              <div class="dc-col md-2">
                <span class="dc-icon wheelchair-access"></span>
              </div>
              <div class="dc-col md-10">
                <div class="padding-vertical">
                  <dc-switch
                    style="justify-content: space-between;"
                    label="{{ 'Wheelchair accessible' | translate }}"
                    formControlName="wheelchair_accessible"
                  ></dc-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dc-col md-6">
        <div class="dc-row space-12">
          <div class="dc-col md-12">
            <h2 class="dc-text-color-primary" translate>Contact</h2>
          </div>
          <div class="dc-col md-6">
            <dc-input
              label="{{ 'Phone 1' | translate }}"
              formControlName="phone_1"
              [errorMessages]="{ required:'Required' | translate }"
            ></dc-input>
          </div>
          <div class="dc-col md-6">
            <dc-input
              label="{{ 'Phone 2' | translate }}"
              formControlName="phone_2"
            ></dc-input>
          </div>
          <div class="dc-col md-6">
            <dc-input
              label="{{ 'Email' | translate }}"
              formControlName="email"
              [errorMessages]="{ required:'Required' | translate }"
            ></dc-input>
          </div>
          <div class="dc-col md-12">
            <h2 class="dc-text-color-primary" translate>Short description</h2>
          </div>
          <div class="dc-col md-12">
            <dc-text-editor
              formControlName="description"
            ></dc-text-editor>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<div class="dc-bottom-bar">
  <div class="dc-container flex" style="height: 100%; align-items: center;">
    <dc-button size="small" color="secondary" (click)="close()">{{ 'Close' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save(false)" [loading]="savingInstitution" [disabled]="institutionForm.invalid">{{ 'Save' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save(true)" [loading]="savingInstitution" [disabled]="institutionForm.invalid">{{ 'Save & Close' | translate }}</dc-button>

  </div>
</div>
