import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient
  ) {
  }

  private formatErrors(error: any) {
    return throwError(error);
  }

  get(path: string, params: any = {}): Observable<any> {
    return this.http.get(`${environment.apiRoot}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(`${environment.apiRoot}${path}`, body)
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http.post(`${environment.apiRoot}${path}`, body)
      .pipe(catchError(this.formatErrors));
  }

  delete(path, params?: any): Observable<any> {
    return this.http.delete(`${environment.apiRoot}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  download(path, filename, params?: any): Observable<any> {
    return this.http.get(`${environment.apiRoot}${path}`, {responseType: 'arraybuffer'})
      .pipe(map((response: any) => {
        const ie = navigator.userAgent.match(/MSIE\s([\d.]+)/);
        const ie11 = navigator.userAgent.match(/Trident\/7.0/) && navigator.userAgent.match(/rv:11/);
        const  ieEDGE = navigator.userAgent.match(/Edge/g);
        const ieVer = (ie ? ie[1] : (ie11 ? 11 : (ieEDGE ? 12 : -1)));

        if (ie && ieVer < 10) {
          console.log('No blobs on IE ver < 10');
          return;
        }

        const file = new Blob([response.data], {type: 'application/pdf'});

        if (ieVer > -1) {
          window.navigator.msSaveBlob(file, filename + '.pdf');
        } else {
          const fileURL = URL.createObjectURL(file);
          const a = document.createElement('a');
          a.href = fileURL;
          a.download = filename + '.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }));
  }

}
