import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Symptom } from '../classes/symptom';

@Injectable({
  providedIn: 'root'
})
export class SymptomService {

  constructor(
    private apiService: ApiService
  ) { }

  getSymptoms( query: any = {} ) {
    return this.apiService.get('symptom', query);
  }

  getSymptom(symptomId: any, query: any = {}) {
    return this.apiService.get(`symptom/${symptomId}`, query);
}

  createSymptom(symptom: Symptom) {
    return this.apiService.post(`symptom`, symptom );
  }

  updateSymptom(symptomId: number, symptom: Symptom) {
    return this.apiService.put(`symptom/${symptomId}`, symptom );
  }

  deleteSymptom(symptomId: number) {
    return this.apiService.delete(`symptom/${symptomId}` );
  }

}
