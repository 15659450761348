import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UiContentService } from '../../services/ui-content.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { ModalService } from '@dc/ui/components/modal/modal.service';

@Component({
  selector: 'main[dc-ui-content-translate]',
  templateUrl: './ui-content-translate.component.html'
})
export class UiContentTranslateComponent implements OnInit {
  @Input() uiContentId: number;
  uiContentTranslations: any[] = [];
  savingUiContent: boolean = false;
  deletingUiContent: boolean = false;

  showLoading: boolean = true;

  constructor(
    private uiContentService: UiContentService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private toasterService: DCToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.getLanguages();
  }

  getUiContent() {
    if (this.uiContentId) {
      this.showLoading = true;
       const params = {
         include: 'translations.language'
      };
      this.uiContentService
        .getUiContent(this.uiContentId, params)
        .subscribe(
          (content) => {
            this.showLoading = false;
            const uiContent = content.data;
            uiContent.translations.map((translation) => {
              this.uiContentTranslations.map((cont) => {
                if (translation.language_id === cont.language_id) {
                  cont.value = translation.value;
                }
              })
            })
          }
        );
    } else {
      this.showLoading = false;
    }
  }

  getLanguages() {
    this.uiContentService.getLanguages()
      .subscribe(
        (res) => {
          const languages = res.data;
          languages.map((lng) => {
            this.uiContentTranslations.push({name:lng.name, language_id: lng.id, value: null})
          })
          this.getUiContent();
        },
        () => {}
      )
  }

  close() {
    this.activeModal.dismiss();
  }

  save(close: boolean = false) {
    if (this.uiContentId) {
      this.savingUiContent = true;
      this.uiContentService
        .updateUiContent(this.uiContentId, {translations: this.uiContentTranslations})
        .subscribe(
          () => {
            this.toasterService.show({
                message: this.translate.instant('Translation was successfully updated.'),
                type: 'success'
            });
            this.savingUiContent = false;
            this.activeModal.close(true)
          },
          () => {
            this.toasterService.show({
              message: this.translate.instant('There was an error while saving the translation.'),
              type: 'error'
            });
            this.savingUiContent = false;
          }
        )
    } else {
      this.savingUiContent = true;
      this.uiContentService
        .createUiContent(this.uiContentId,{translations: this.uiContentTranslations})
        .subscribe(
          () => {
            this.toasterService.show({
                message: this.translate.instant('Translate was successfully created.'),
                type: 'success'
            });
            this.savingUiContent = false;
            this.activeModal.close(true)

          },
          () => {
            this.toasterService.show({
              message: this.translate.instant('There was an error while saving the translation.'),
              type: 'error'
            });
            this.savingUiContent = false;
          }
        );
    }
  }

  delete() {
    if (!this.uiContentId) {
      return;
    }
    this.modalService.confirm({
      title: this.translate.instant('Delete'),
      body: this.translate.instant('Are you sure you want to delete this translation?'),
      size: 'small',
      buttons: [
        {
          text: this.translate.instant('No'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('Yes'),
          handler: () => {
            this.deletingUiContent = true;
            this.uiContentService
              .deleteUiContent(this.uiContentId)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: this.translate.instant('Translation successfully deleted.'),
                    type: 'success'
                  });
                  this.deletingUiContent= false;
                  this.activeModal.close(true)
                },
                () => {
                  this.toasterService.show({
                    message: this.translate.instant('Failed to delete the translation.'),
                    type: 'error'
                  });
                  this.deletingUiContent = false;
                  this.activeModal.close(true)
                }
              );
          }
        }
      ]
    });
  }

}
