<div class="dc-root">
  <header class="dc-header" [class.show-nav]="showNav">
    <div class="application">
      <div class="application-logo"></div>
      <nav class="application-navigation">
        <ul>
          <li>
            <a [routerLink]="['/dashboard']" routerLinkActive="active" translate (click)="toggleNav()">Dashboard</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'list']"  [class.active]="router.url.includes('institution')" translate (click)="toggleNav()">Institutions</a>
          </li>
          <li>
            <a [routerLink]="['/settings']" [class.active]="router.url.includes('settings')" translate (click)="toggleNav()">Settings</a>
          </li>
        </ul>
      </nav>
      <div class="nav-toggle" (click)="toggleNav()"></div>
    </div>
      <div class="profile">
        <div class="user" (click)="toggleProfileNav()">
          <img [src]="user?.photo_url ? user.photo_url : 'assets/images/placeholder.svg'">
          <div class="full-name">{{ user?.first_name }} {{ user?.last_name }}</div>

          <ul class="user-navigation" [class.show]="showProfileNav">
            <li>
              <a [routerLink]="['./profile']">
                <span class="dc-icon settings"></span>
                <span translate>Settings</span>
              </a>
            </li>
            <li>
              <a (click)="logout()">
                <span class="dc-icon logout"></span>
                <span translate>Log out</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
  </header>
  <router-outlet></router-outlet>
</div>

