<span class="dc-switch__label">{{ label }}</span>
<div class="dc-switch" [ngClass]="{'disabled': disabled}">
  <label>
    <input
      type="checkbox"
      [ngClass]="{ 'checked': value, 'mobile': platform === 'mobile' }"
      (change)="onChangeStatus($event);"
      [name]="name"
      [checked]="value"
      [disabled]="disabled">
    <span class="dc-switch__slider round"></span>
  </label>
</div>

