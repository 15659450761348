import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCInfiniteScrollComponent } from './infinite-scroll.component';



@NgModule({
  declarations: [
    DCInfiniteScrollComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DCInfiniteScrollComponent
  ]
})
export class DCInfiniteScrollModule { }
