<div class="dc-section">
  <div class="dc-container">
    <div class="dc-row center">
      <div class="dc-col md-4">
        <dc-input
          [(ngModel)]="searchTerm"
          (ngModelChange)="getUiContents()"
          [placeholder]="'Search' | translate"
          [showLabel]="false"
        ></dc-input>
      </div>
      <div class="dc-col md-8 end">
        <dc-button size="small" color="primary" icon="plus" (click)="translateUiContent!(uiContentId)">{{ 'Add' | translate }}</dc-button>
      </div>
    </div>
  </div>
</div>
<div class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}" dcInfiniteScroll (scrolled)="getUiContents(true)">
  <div [dcLoading]="loadingUiContents"></div>
  <div class="dc-container">
    <table class="dc-table">
      <tbody>
      <tr class="dc-clickable" *ngFor="let uiContent of uiContents" (click)="translateUiContent(uiContent.id)">
        <td>
          <label class="dc-table__label" translate>Text</label>
          <div class="dc-table__value">
            <span>{{ uiContent.value }}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
