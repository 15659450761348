<div class="dc-subheader">
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col xs-6">
        <h1 translate>Institutions</h1>
      </div>
      <div class="dc-col xs-6 end">
        <dc-button size="small" color="white" icon="plus" [routerLink]="[ '..', 'new' ]">{{ 'Add' | translate }}</dc-button>
      </div>
    </div>
  </div>
</div>
<div class="dc-section">
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col md-6 lg-4">
        <dc-input
          [(ngModel)]="searchTerm"
          (ngModelChange)="getInstitutions()"
          [placeholder]="'Search' | translate"
          [showLabel]="false"
        ></dc-input>
      </div>
      <div class="dc-col md-8 padding-left p-small">
        <dc-button color="secondary" (click)="showFilterModal()">{{ 'Filter' | translate }}</dc-button>
      </div>
      <!-- <div class="dc-col md-2">
        <dc-button color="secondary">{{ 'Filter' | translate }}</dc-button>
      </div> -->
    </div>
  </div>
</div>
<div class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}" dcInfiniteScroll (scrolled)="getInstitutions(true)">
  <div [dcLoading]="loadingInstitutions"></div>
  <div class="dc-container">
    <table class="dc-table">
      <tbody>
      <tr class="dc-clickable" *ngFor="let institution of institutions" [routerLink]="[ '..', institution.id, 'details' ]">
        <td>
          <label class="dc-table__label" translate>Institution name</label>
          <div class="dc-table__value">
            <span>{{ institution.name }}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
