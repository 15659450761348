import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ModalService } from 'libs/ui/src/public-api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-change-password-request',
  templateUrl: './change-password-request.component.html'
})
export class ChangePasswordRequestComponent implements OnInit {
  changePasswordRequestForm: FormGroup;

  sendingRequest: boolean = false;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.changePasswordRequestForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
    });
  }

  sendRequest() {
    this.sendingRequest = true;
    this.userService.sendPasswordChangeRequest(this.changePasswordRequestForm.value)
      .subscribe(
        () => {
          this.modalService.alert({
            title: 'Success',
            body: this.translate.instant('Password change request sent. Please check your email.'),
            size: 'small',
            type: 'default',
            button: {
              text: this.translate.instant('Okay'),
              role: 'cancel'
            }
          });
          this.sendingRequest = false;
        },
        () => {
          this.modalService.alert({
            title: this.translate.instant('Error'),
            body: this.translate.instant('Wrong email address.'),
            size: 'small',
            type: 'danger',
            button: {
              text: this.translate.instant('Okay'),
              role: 'cancel'
            }
          });
          this.sendingRequest = false;
        }
      );
  }

}
