import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from '../../services/user.service';
import { User } from '../../classes/user';
import { AppConfig } from '../../app.config';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DCToastService } from 'libs/ui/src/lib/components/toast/toast.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'main [dc-profile]',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  user: User = new User();
  userForm: FormGroup;
  passwordForm: FormGroup;

  loadingUser: boolean = false;
  savingUser: boolean = false;
  changingPassword: boolean = false;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private toasterService: DCToastService,
    private translate: TranslateService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getUser();
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      photo_url: [''],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]]
    });
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirm: ['', [Validators.required]]
    });
  }

  getUser() {
    this.loadingUser = true;
    this.userService
      .getUser('current')
      .subscribe(
        (response) => {
          this.user = response.data;
          this.userForm.patchValue(response.data);
          this.loadingUser = false;
        },
        () => {
          this.loadingUser = false;
        }
      );
  }

  changePassword() {
    if (this.passwordForm.get('password').value !== this.passwordForm.get('confirm').value) {
      this.toasterService.show({
        message: this.translate.instant('Passwords do not match.'),
        type: 'error'
      });
      return;
    }

    if (!AppConfig.PASSWORD_REGEXP.test(this.passwordForm.get('password').value)) {
      this.toasterService.show({
        message: this.translate.instant('Password should be at least 8 characters long and contain at least one digit.'),
        type: 'error'
      });
      return;
    }

    this.changingPassword = true;
    this.userService
      .updateCurrentUser(this.passwordForm.value)
      .subscribe(
        () => {
          this.toasterService.show({
            message: this.translate.instant('Password updated.'),
            type: 'success'
          });
          this.passwordForm.reset();
          this.changingPassword = false;
        },
        () => {
          this.toasterService.show({
            message: this.translate.instant('Password not changed.'),
            type: 'error'
          });
          this.changingPassword = false;
        }
      );
  }

  saveUser() {
    this.savingUser = true;
    this.userService
      .updateCurrentUser(this.userForm.value)
      .subscribe(
        (response) => {
          this.toasterService.show({
            message: this.translate.instant('Changes saved.'),
            type: 'success'
          });
          this.userService.getCurrentUser();
          this.user = response.data;
          this.userForm.patchValue(response.data);
          this.savingUser = false;
        },
        () => {
          this.toasterService.show({
            message: this.translate.instant('Changes were not saved.'),
            type: 'error'
          });
          this.savingUser = false;
        }
      );
  }

  close() {
    this.location.back();
  }

}
