import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  private progressSource = new BehaviorSubject(null);
  progress = this.progressSource.asObservable();

  constructor() { }

  start() {
    this.progressSource.next(true);
  }

  stop() {
    this.progressSource.next(false);
  }
}
