<section class="dc-section symptom dc-content" [perfectScrollbar]="{ suppressScrollX: true }">
  <div [dcLoading]="showLoading"></div>
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col sm-6">
        <h1>
          <div class="super-heading" translate>Symptom</div>
          <span>{{ symptom.name }}</span>
        </h1>
      </div>
      <div class="dc-col sm-6 flex padding-bottom" style="flex-direction: row; justify-content: flex-end;">
      <dc-button *ngIf="symptomId" color="outline" icon="delete" size="small" [loading]="deletingSymptom" (click)="delete()">{{ 'Delete symptom' | translate }}</dc-button>
    </div>
    </div>
    <form class="dc-row space-16" [formGroup]="symptomForm">
      <div class="dc-col md-12">
        <div class="dc-row space-16">
          <div class="dc-col md-12">
            <h2 class="dc-text-color-primary" translate>Information</h2>
          </div>
          <div class="dc-col md-4">
            <dc-input
              label="{{ 'Symptom name' | translate }}"
              placeholder="{{ 'Symptom name' | translate }}"
              formControlName="name"
              [errorMessages]="{ required:'Required' | translate }"
            ></dc-input>
          </div>
          <div class="dc-col md-4">
            <dc-select
              label="{{ 'Region' | translate }}"
              placeholder="{{ 'Select region' | translate }}"
              formControlName="region"
              [options]="regions"
              [errorMessages]="{ required:'Required' | translate }"
            ></dc-select>
          </div>
          <div class="dc-col md-4">
            <dc-select
              label="{{ 'Gender' | translate }}"
              placeholder="{{ 'Select gender' | translate }}"
              formControlName="gender"
              [options]="gender"
            ></dc-select>
          </div>
        </div>
        <div class="dc-col md-12">
          <div class="dc-row space-16">
            <div class="dc-col md-4" >
              <dc-select
                label="{{ 'Outcome' | translate }}"
                placeholder="{{ 'Select outcome' | translate }}"
                [(ngModel)]="outcomes.outcome1"
                [ngModelOptions]="{ standalone: true }"
                [options]="outcome"
                [required]="true"
                (ngModelChange)="outcomeChange(outcomes.outcome1, 'direct')"
              ></dc-select>
            </div>
          </div>
        </div>
        <div class="dc-col md-12" *ngIf="outcomes.outcome1 && outcomes.outcome1 === 'Specialty' ">
          <div class="dc-row space-16">
            <div class="dc-col md-4" >
              <dc-select
                placeholder="{{ 'Direct' | translate }}"
                formControlName="direct"
                [options]="specialties"
              ></dc-select>
            </div>
          </div>
        </div>
        <div class="dc-col md-12" *ngIf="outcomes.outcome1 === 'Next question'">
          <div class="dc-row space-16">
            <div class="dc-col md-12">
              <h3 class="dc-text-color-primary">{{ 'Question 1' | translate }}</h3>
            </div>
            <div class="dc-col md-4">
              <dc-input
                label="{{'Question text' | translate}}"
                placeholder="{{ 'Question text' | translate }}"
                formControlName="question_1"
                [errorMessages]="{ required:'Required' | translate }"
              ></dc-input>
            </div>
            <div class="dc-col md-4">
              <dc-select
                label="{{ 'Positive answer' | translate }}"
                placeholder="{{ 'Select outcome' | translate }}"
                [(ngModel)]="outcomes.outcome2Positive"
                [ngModelOptions]="{ standalone: true }"
                [options]="outcomes.outcome2Negative !== 'Next question' ? outcome : outcomeDiff"
                (ngModelChange)="outcomeChange(outcomes.outcome2Positive, 'answer_1_positive')"
                [required]="true"
              ></dc-select>
              <dc-select
                class="margin-top"
                *ngIf="outcomes.outcome2Positive === 'Specialty'"
                placeholder="{{ 'Positive answer' | translate }}"
                formControlName="answer_1_positive"
                [options]="specialties"
              ></dc-select>
            </div>
            <div class="dc-col md-4">
              <dc-select
                label="{{ 'Negative answer' | translate }}"
                placeholder="{{ 'Select outcome' | translate }}"
                [(ngModel)]="outcomes.outcome2Negative"
                [ngModelOptions]="{ standalone: true }"
                [options]="outcomes.outcome2Positive !== 'Next question' ? outcome : outcomeDiff"
                (ngModelChange)="outcomeChange(outcomes.outcome2Negative, 'answer_1_negative')"
                [required]="true"
              ></dc-select>
              <dc-select
                class="margin-top"
                *ngIf="outcomes.outcome2Negative === 'Specialty'"
                placeholder="{{ 'Negative answer' | translate }}"
                formControlName="answer_1_negative"
                [options]="specialties"
              ></dc-select>
            </div>
          </div>
      </div>
        <div class="dc-col md-12" *ngIf="(outcomes.outcome2Positive === 'Next question' || outcomes.outcome2Negative === 'Next question') && outcomes.outcome1 === 'Next question'">
          <div class="dc-row space-16">
            <div class="dc-col md-12">
              <h3 class="dc-text-color-primary">{{ 'Question 2' | translate }}</h3>
            </div>
            <div class="dc-col md-4" >
              <dc-input
                label="{{ 'Question text' | translate }}"
                placeholder="{{ 'Question text' | translate }}"
                formControlName="question_2"
                [errorMessages]="{ required:'Required' | translate }"
              ></dc-input>
            </div>
            <div class="dc-col md-4">
              <dc-select
                label="{{ 'Positive answer' | translate }}"
                placeholder="{{ 'Select outcome' | translate }}"
                [(ngModel)]="outcomes.outcome3Positive"
                [ngModelOptions]="{ standalone: true }"
                [options]="outcomeDiff"
                (ngModelChange)="outcomeChange(outcomes.outcome3Positive, 'answer_2_positive')"
                [required]="true"
              ></dc-select>
              <dc-select
                class="margin-top"
                *ngIf="outcomes.outcome3Positive === 'Specialty'"
                placeholder="{{ 'Positive answer' | translate }}"
                formControlName="answer_2_positive"
                [options]="specialties"
              ></dc-select>
            </div>
            <div class="dc-col md-4">
              <dc-select
                label="{{ 'Negative answer' | translate }}"
                placeholder="{{ 'Select outcome' | translate }}"
                [(ngModel)]="outcomes.outcome3Negative"
                [ngModelOptions]="{ standalone: true }"
                [options]="outcomeDiff"
                (ngModelChange)="outcomeChange(outcomes.outcome3Negative, 'answer_2_negative')"
                [required]="true"
              ></dc-select>
              <dc-select
                class="margin-top"
                *ngIf="outcomes.outcome3Negative === 'Specialty'"
                placeholder="{{ 'Negative answer' | translate }}"
                formControlName="answer_2_negative"
                [options]="specialties"
              ></dc-select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<div class="dc-bottom-bar">
  <div class="dc-container flex" style="height: 100%; align-items: center;">
    <dc-button size="small" color="secondary" (click)="close()">{{ 'Close' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save(false)" [loading]="savingSymptom" [disabled]="symptomForm.invalid">{{ 'Save' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save(true)" [loading]="savingSymptom" [disabled]="symptomForm.invalid">{{ 'Save & Close' | translate }}</dc-button>
  </div>
</div>
