import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Specialty } from '../../classes/specialty';
import { SpecialtyService } from '../../services/specialty.service';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { Symptom } from '../../classes/symptom';
import { SymptomService } from '../../services/symptom.service';
import { ActivatedRoute, convertToParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalService } from '@dc/ui/components/modal/modal.service';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../services/auth.service';
import {User} from '../../classes/user';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'main [dc-symptoms-details]',
  templateUrl: './symptom-details.component.html'
})
export class SymptomDetailsComponent implements OnInit, OnDestroy {
  symptomForm: FormGroup;
  symptom: Symptom = new Symptom();
  symptomId: number;
  specialties: Specialty[] = [];
  subscriptions: Subscription[] = [];
  regions: any[] = [
    {id: 'front-head', name: 'Front head'},
    {id: 'front-neck', name: 'Front neck'},
    {id: 'back-head', name: 'Back head'},
    {id: 'back-neck', name: 'Back neck'},
    {id: 'front-shoulder', name: 'Front shoulder'},
    {id: 'back-shoulder', name: 'Back shoulder'},
    {id: 'chest', name: 'Chest'},
    {id: 'abdomen', name: 'Abdomen'},
    {id: 'pelvis', name: 'Pelvis'},
    {id: 'back', name: 'Back'},
    {id: 'legs', name: 'Legs'},
    {id: 'unease', name: 'Unease'}
  ];
  gender: any[] = [
    {id: 'F', name: 'Female'},
    {id: 'M', name: 'Male'}
  ];
  outcome: any[] = [
    {id: 'Next question', name: 'Next question'},
    {id: 'Specialty', name: 'Specialty'},
    {id: 'Urgent', name: 'Urgent'}
  ];
  outcomeDiff: any[] = [
    {id: 'Specialty', name: 'Specialty'},
    {id: 'Urgent', name: 'Urgent'}
  ];

  outcomes = {
    outcome1: null,
    outcome2Positive: null,
    outcome2Negative: null,
    outcome3Positive: null,
    outcome3Negative: null
  }

  showLoading: boolean = false;
  savingSymptom: boolean = false;
  deletingSymptom: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private toasterService: DCToastService,
    private symptomService: SymptomService,
    private specialtyService: SpecialtyService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getSpecialties();
    this.initForm();
    const routeSubscription = this.route.params.subscribe(
      (data) => {
        if (data.symptomId) {
          this.symptomId = +data.symptomId;
          this.getSymptom();
        }
      }
    );
    this.subscriptions.push(routeSubscription);
  }


    ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.symptomForm = this.formBuilder.group({
      direct: [null, [Validators.required]],
      gender: [null],
      name: ['', [Validators.required]],
      region: ['', [Validators.required]],
      question_1: [''],
      answer_1_negative: [null],
      answer_1_positive: [null],
      answer_2_negative: [null],
      answer_2_positive: [null],
      question_2: ['']
    });
  }

  outcomeChange(outcome, control) {
    if ((control === 'direct' || control === 'answer_1_positive' || control === 'answer_1_negative' || control === 'answer_2_negative' || control === 'answer_2_positive') && outcome !== 'Specialty') { this.symptomForm.get(control).reset(); }
    if (outcome === 'Urgent') {
      this.symptomForm.get(control).setValue(-1);
    } else {
      if (this.symptomForm.get(control).value === -1) {
        this.symptomForm.get(control).setValue([]);
        this.symptomForm.get(control).setValue(null);
      }
    }
    this.setFormValidators(outcome, control);
  }

  setFormValidators(outcome, control) {
    const direct = this.symptomForm.get('direct');
    const question1 = this.symptomForm.get('question_1');
    const answer1Positive = this.symptomForm.get('answer_1_positive');
    const answer1Negative = this.symptomForm.get('answer_1_negative');
    const question2 = this.symptomForm.get('question_2');
    const answer2Positive = this.symptomForm.get('answer_2_positive');
    const answer2Negative = this.symptomForm.get('answer_2_negative');

    if (!outcome && control === 'direct') {
      direct.setValidators(Validators.required);
    }
    if (outcome === 'Urgent' && control === 'direct') {
      direct.clearValidators();
      question1.clearValidators();
      answer1Positive.clearValidators();
      answer1Negative.clearValidators();
      question2.clearValidators();
      answer2Positive.clearValidators();
      answer2Negative.clearValidators();
    }
    if (outcome === 'Specialty' && control === 'direct') {
      direct.setValidators(Validators.required);
      question1.clearValidators();
      answer1Positive.clearValidators();
      answer1Negative.clearValidators();
      question2.clearValidators();
      answer2Positive.clearValidators();
      answer2Negative.clearValidators();
    }
    if (outcome === 'Next question' && control === 'direct') {
      direct.clearValidators();
      question1.setValidators(Validators.required);
      answer1Positive.setValidators(Validators.required);
      answer1Negative.setValidators(Validators.required);
      question2.clearValidators();
      answer2Positive.clearValidators();
      answer2Negative.clearValidators();
    }
    if (outcome === 'Next question' && control === 'direct') {
        if (this.outcomes.outcome2Positive === 'Next question' || this.outcomes.outcome2Negative === 'Next question') {
        answer1Positive.clearValidators();
        answer1Negative.clearValidators();
        question2.setValidators(Validators.required);
        answer2Positive.setValidators(Validators.required);
        answer2Negative.setValidators(Validators.required);
      }
    }

    if (!outcome && control === 'answer_1_positive') {
      answer1Positive.setValidators(Validators.required);
    }
    if (!outcome && control === 'answer_1_negative') {
      answer1Negative.setValidators(Validators.required);
    }
    if (outcome === 'Next question' && control === 'answer_1_positive') {
      answer1Positive.clearValidators();
      question2.setValidators(Validators.required);
      answer2Positive.setValidators(Validators.required);
      answer2Negative.setValidators(Validators.required);
    }
    if (outcome === 'Next question' && control === 'answer_1_negative') {
      answer1Negative.clearValidators();
      question2.setValidators(Validators.required);
      answer2Positive.setValidators(Validators.required);
      answer2Negative.setValidators(Validators.required);
    }
    if (outcome === 'Specialty' && control === 'answer_1_positive') {
      answer1Positive.setValidators(Validators.required);
    }
    if (outcome === 'Specialty' && control === 'answer_1_negative') {
      answer1Negative.setValidators(Validators.required);
    }

    if (outcome === 'Urgent' && control === 'answer_1_positive') {
      if (this.outcomes.outcome2Negative !== 'Next question') {
        question2.clearValidators();
        answer2Positive.clearValidators();
        answer2Negative.clearValidators();
      }
    }
    if (outcome === 'Urgent' && control === 'answer_1_negative') {
      if (this.outcomes.outcome2Positive !== 'Next question') {
        question2.clearValidators();
        answer2Positive.clearValidators();
        answer2Negative.clearValidators();
      }
    }

    direct.updateValueAndValidity();
    question1.updateValueAndValidity();
    question2.updateValueAndValidity();
    answer1Positive.updateValueAndValidity();
    answer1Negative.updateValueAndValidity();
    answer2Positive.updateValueAndValidity();
    answer2Negative.updateValueAndValidity();
  }

  setOptionForOutcomeSelect() {

    if (this.symptomForm.get('direct').value === -1) {
      this.outcomes.outcome1 = 'Urgent';
    } else if (!this.symptomForm.get('direct').value) {
      this.outcomes.outcome1 = 'Next question';
    } else {
      this.outcomes.outcome1 = 'Specialty';
    }

    if (this.symptomForm.get('answer_1_positive').value === -1) {
      this.outcomes.outcome2Positive = 'Urgent';
    } else if (!this.symptomForm.get('answer_1_positive').value) {
      this.outcomes.outcome2Positive = 'Next question';
    } else {
      this.outcomes.outcome2Positive = 'Specialty';
    }

    if (this.symptomForm.get('answer_1_negative').value === -1) {
      this.outcomes.outcome2Negative= 'Urgent';
    } else if (!this.symptomForm.get('answer_1_negative').value) {
      this.outcomes.outcome2Negative= 'Next question';
    } else {
      this.outcomes.outcome2Negative = 'Specialty';
    }

    if (this.symptomForm.get('answer_2_positive').value === -1) {
      this.outcomes.outcome3Positive = 'Urgent';
     }
    //else if (!this.symptomForm.get('answer_2_positive').value) {
    //   this.outcomes.outcome3Positive = 'Next question';
    // }
      else {
      this.outcomes.outcome3Positive = 'Specialty';
    }

    if (this.symptomForm.get('answer_2_negative').value === -1) {
      this.outcomes.outcome3Negative= 'Urgent';
    }
    // else if (!this.symptomForm.get('answer_2_negative').value) {
    //   this.outcomes.outcome3Negative= 'Next question';
    // }
    else {
      this.outcomes.outcome3Negative = 'Specialty';
    }

  }

  getSymptom() {
    if (this.symptomId) {
      this.showLoading = true;
      this.symptomService.getSymptom(this.symptomId)
        .subscribe(
          (symptom) => {
            this.symptomForm.patchValue(symptom.data);
            this.symptom = symptom.data;
             if (this.symptomForm.value.direct) { this.symptomForm.get('direct').setValue(symptom.data.direct) }
            this.showLoading = false;
            this.setOptionForOutcomeSelect();
            this.outcomeChange(this.outcomes.outcome1, 'direct')
          },
          () => {
            this.showLoading = false;
          }
        );
    }
  }

  getSpecialties() {
    this.showLoading = true;
    const params = {
      pagination: false
    };
    this.specialtyService.getSpecialties(params)
      .subscribe(
        (response) => {
          this.specialties = response.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toasterService.show({
            message: this.translate.instant('Failed to load specialties.'),
            type: 'error'
          });
        }
      );
  }

  save(close: boolean = false) {
    let formValues: any = {};
    if (this.outcomes.outcome1 !== 'Next question') {
      formValues.direct = this.symptomForm.get('direct').value;
      formValues.gender = this.symptomForm.get('gender').value;
      formValues.name = this.symptomForm.get('name').value;
      formValues.region = this.symptomForm.get('region').value;
    } else {
      if ((this.outcomes.outcome2Positive || this.outcomes.outcome2Negative) !== 'Next question') {
        formValues.direct = this.symptomForm.get('direct').value;
        formValues.gender = this.symptomForm.get('gender').value;
        formValues.name = this.symptomForm.get('name').value;
        formValues.region = this.symptomForm.get('region').value;
        formValues.question_1 = this.symptomForm.get('question_1').value;
        formValues.answer_1_negative = this.symptomForm.get('answer_1_negative').value;
        formValues.answer_1_positive = this.symptomForm.get('answer_1_positive').value;
      }
      if (this.outcomes.outcome2Positive === 'Next question' || this.outcomes.outcome2Negative === 'Next question') {
        formValues = this.symptomForm.value;
      }
    }
    if (this.symptomForm.invalid) { return; }

    if (this.symptomId) {
      this.savingSymptom = true;
      this.symptomService
        .updateSymptom(this.symptomId, formValues)
        .subscribe(
          (response: { data: Symptom }) => {
            this.toasterService.show({
              message: this.translate.instant('Symptom was successfully updated.'),
              type: 'success'
            });

            this.symptomForm.patchValue(response.data);
            this.savingSymptom = false;
            if (close) {
              this.close();
            }
            else {
              if (this.symptomForm.value.direct) { this.symptomForm.get('direct').setValue(response.data.direct) }
            }
          },
          () => {
            this.toasterService.show({
              message: this.translate.instant('There was an error while saving the symptom.'),
              type: 'error'
            });
            this.savingSymptom = false;
          }
        );
    } else {
      this.savingSymptom = true;
      this.symptomService
        .createSymptom(formValues)
        .subscribe(
          (response: { data: Symptom }) => {
            this.toasterService.show({
              message: this.translate.instant('Symptom was successfully created.'),
              type: 'success'
            });

            this.symptomId = response.data.id;
            this.symptomForm.patchValue(response.data);
            this.savingSymptom = false;

            if (close) {
              this.close();
            } else {
              this.router.navigate(['./symptom/' + this.symptomId]);
              if (this.symptomForm.value.direct) { this.symptomForm.get('direct').setValue(response.data.direct) }
            }
          },
          () => {
            this.toasterService.show({
              message: this.translate.instant('There was an error while saving the symptom.'),
              type: 'error'
            });
            this.savingSymptom = false;
          }
        );
    }
  }

  delete() {
    if (!this.symptomId) {
      return;
    }

    this.modalService.confirm({
      title: this.translate.instant('Delete'),
      body: this.translate.instant('Are you sure you want to delete this symptom?'),
      size: 'small',
      buttons: [
        {
          text: this.translate.instant('No'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('Yes'),
          handler: () => {
            this.deletingSymptom = true;
            this.symptomService
              .deleteSymptom(this.symptomId)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: this.translate.instant('Symptom succesfully deleted.'),
                    type: 'success'
                  });
                  this.deletingSymptom = false;
                  this.close();
                },
                () => {
                  this.toasterService.show({
                    message: this.translate.instant('Failed to delete the symptom.'),
                    type: 'error'
                  });
                  this.deletingSymptom = false;
                }
              );
          }
        }
      ]
    });
  }

  close() {
    this.router.navigate(['settings', 'symptom', 'list']);
  }

}

