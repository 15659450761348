import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { User } from '../classes/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>(null as User);
  currentUser = this.currentUserSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getUser(userId: number|'current', query: any = {}) {
    return this.apiService.get(`user/${userId}`, query);
  }

  updateUser(userId: number|'current', user: User) {
    return this.apiService.put(`user/${userId}`, user);
  }

  updateCurrentUser(user: User) {
    return this.updateUser('current', user);
  }

  sendPasswordChangeRequest(email: string) {
    return this.apiService.post(`user/change_password_request`, { email });
  }

  changePassword(params: any) {
    return this.apiService.post(`user/change_password`, params);
  }

  getCurrentUser() {
    return this.getUser('current', { include: 'permissions' })
      .subscribe(
        (response) => {
          this.currentUserSubject.next(response.data);
        }
      );
  }

  uploadPhoto(formData: any) {
    return this.apiService.post(`user/current/photo`, formData);
  }

  removePhoto() {
    return this.apiService.delete(`user/current/photo`);
  }

}
