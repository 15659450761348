<div class="dc-modal small">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">{{ 'Add city' | translate }}</h2>
    <span class="dc-modal__close" tabindex="0" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [dcLoading]="showLoading">
    <div class="dc-row">
      <div class="dc-col">
        <dc-input
          label="{{'City' | translate}}"
          [(ngModel)]="name"
        ></dc-input>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button (click)="save()">{{ 'Save' | translate }}</dc-button>
    </div>
  </div>
</div>
