export class Symptom {
  id: number;
  name: string;
  region: string;
  gender: string;
  question_1: string;
  question_2: string;
  answer_1_positive: string;
  answer_1_negative: string;
  answer_2_positive: string;
  answer_2_negative: string;
  direct: string;
}
