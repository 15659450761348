import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCSlidesComponent } from './slides.component';
import { DCSlideComponent } from './slide.component';
import { SwiperModule } from 'ngx-swiper-wrapper';



@NgModule({
  declarations: [
    DCSlidesComponent,
    DCSlideComponent
  ],
  imports: [
    CommonModule,
    SwiperModule
  ],
  exports: [
    DCSlidesComponent,
    DCSlideComponent
  ]
})
export class DCSliderModule { }
