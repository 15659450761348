<div class="dc-container">
  <div class="dc-row middle">
    <div class="dc-col xs-6">
      <h3 translate>Institutions</h3>
    </div>
  </div>
</div>
<div class="dc-section dc-content" >
  <div [dcLoading]="loadingInstitutions"></div>
  <div class="dc-container">
    <div class="table-wrapper position-relative" [perfectScrollbar]="{ suppressScrollX: true}" dcInfiniteScroll (scrolled)="getInstitutions(true)">
      <table class="dc-table break-phone padding p-small">
      <tr class="dc-clickable" *ngFor="let institution of institutions" [routerLink]="['/institution/' + institution.id + '/details' ]">
        <td style="width: 80px;">
          <div class="dc-table__value">
            <img [width] = "48" [height]="48" [src]="institution.logo_url" alt="">
          </div>
        </td>
        <td>
          <label class="dc-text-color-primary" translate>{{institution.name}}</label>
          <div class="dc-table__value">
            <span> {{ institution.address }} </span>
          </div>
        </td>
        <td style="width: 150px;">
          <label class="dc-table__label" translate>Plan</label>
          <div class="dc-table__value">
<!--            <span *ngIf="patient.last_visit">{{ patient.last_visit | amDateFormat:'DD.MM.YYYY.' }}</span>-->
<!--            <span *ngIf="!patient.last_visit">-</span>-->
          </div>
        </td>
        <td style="width: 200px;">
          <label class="dc-table__label" translate>Subscription expires</label>
          <div class="dc-table__value">
<!--            <span *ngIf="patient.last_visit">{{ patient.last_visit | amDateFormat:'DD.MM.YYYY.' }}</span>-->
<!--            <span *ngIf="!patient.last_visit">-</span>-->
          </div>
        </td>
        <td style="width: 150px;">
          <label class="dc-table__label" translate>Open</label>
          <div class="dc-table__value">
<!--            <span *ngIf="patient.last_visit">{{ patient.last_visit | amDateFormat:'DD.MM.YYYY.' }}</span>-->
<!--            <span *ngIf="!patient.last_visit">-</span>-->
          </div>
        </td>
        <td style="width: 180px;">
          <label class="dc-table__label" translate>Free</label>
          <div class="dc-table__value">
<!--            <span *ngIf="patient.last_visit">{{ patient.last_visit | amDateFormat:'DD.MM.YYYY.' }}</span>-->
<!--            <span *ngIf="!patient.last_visit">-</span>-->
          </div>
        </td>
      </tr>
    </table>
    </div>
  </div>
</div>
