import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCCollapseComponent } from './collapse.component';
import { CollapseDirective } from './collapse.directive';



@NgModule({
  declarations: [
    DCCollapseComponent,
    CollapseDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DCCollapseComponent,
    CollapseDirective
  ]
})
export class DCCollapseModule { }
