import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCToastComponent } from './toast.component';
import { DCToastService } from './toast.service';



@NgModule({
  declarations: [
    DCToastComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    DCToastService
  ],
  exports: [
    DCToastComponent
  ]
})
export class DCToastModule { }
