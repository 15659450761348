import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User } from '../../classes/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  email: string = '';
  password: string = '';
  error: string = null;
  loggingIn: boolean = false;
  user: User = null;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
  ) {
  }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    const formSubscription = this.loginForm.valueChanges
      .subscribe(() => {
        this.error = null;
      });
    this.subscriptions.push(formSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  login() {
    if (this.loginForm.invalid) { return; }
    this.loggingIn = true;

    this.authService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        () => {
          this.userService.getCurrentUser();
            this.router.navigate(['/dashboard']);
                this.loggingIn = false;
              },
              (error) => {
                this.loggingIn = false;
                this.error = error.error.message;
              }
          );
    }

  clearError() {
    this.error = null;
  }
}
