import { Component, Input, OnInit } from '@angular/core';
import { Specialty } from '../../classes/specialty';
import { Country } from '../../classes/country';
import { Institution } from '../../classes/institution';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { CountryService } from '../../services/country.service';
import { SpecialtyService } from '../../services/specialty.service';
import { InstitutionService } from '../../services/institution.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'main[dc-institution-filter]',
  templateUrl: './institution-filter.component.html'
})
export class InstitutionFilterComponent implements OnInit {
  @Input() filters: {
    specialty_ids: any,
    country_id: any,
    city_id: any
  };
  specialties: Specialty[] = [];
  countries: Country[] = [];
  institution: Institution = new Institution();
  institutionId: number;
  showLoading: boolean = false;
  cities: any[];


  constructor(
    private activeModal: NgbActiveModal,
    private toasterService: DCToastService,
    private countryService: CountryService,
    private specialtyService: SpecialtyService,
    private institutionService: InstitutionService
  ) { }

  ngOnInit(): void {
    this.getSpecialties();
    this.getCountries();
    this.getCities();
  }

  getCountries() {
    this.showLoading = true;
    this.countryService
      .getCountries()
      .subscribe(
        (response) => {
          this.countries = response.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toasterService.show({
            message: 'Failed to load countries.',
            type: 'error'
          });
        }
      );
  }

  getSpecialties() {
    this.showLoading = true;
    const params = {
      pagination: false
    };
    this.specialtyService.getSpecialties(params)
      .subscribe(
        (response) => {
          this.specialties = response.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toasterService.show({
            message: 'Failed to load specialties.',
            type: 'error'
          });
        }
      );
  }

  getCities(country_id?: number) {
    const params: any = {};
    if(country_id) {
      params.country_id = country_id;
    }
    this.institutionService.getCities(params)
      .subscribe(
        (response: any) => {
          this.cities = response.data;
        },
        () => {}
      );
  }

  apply() {
    this.activeModal.close(this.filters);
  }

  removeFilters() {
    this.filters = {
      specialty_ids: null,
      country_id: null,
      city_id: null
    };
    this.activeModal.close(this.filters);
    console.log(this.filters);
  }

  close() {
    this.activeModal.dismiss();
  }
}
