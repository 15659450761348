<div class="dc-modal small">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">{{ 'Filters' | translate }}</h2>
    <span class="dc-modal__close" tabindex="0" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [dcLoading]="showLoading">
    <div class="dc-row">
      <div class="dc-col">
        <dc-select
          label="{{'Specialty' | translate}}"
          [(ngModel)]="filters.specialty_ids"
          [options]="specialties"
          placeholder="{{'Select specialties' | translate}}"
          multi
        ></dc-select>
      </div>
      <div class="dc-col">
        <dc-select
          label="{{'Country' | translate}}"
          [(ngModel)]="filters.country_id"
          [options]="countries"
          placeholder="{{'Select country' | translate}}"
          (ngModelChange)="getCities($event)"
        ></dc-select>
      </div>
      <div class="dc-col">
        <dc-select
          label="{{'City' | translate}}"
          [(ngModel)]="filters.city_id"
          [options]="cities"
          placeholder="{{'Select city' | translate}}"
        ></dc-select>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="removeFilters()">{{ 'Remove filters' | translate }}</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
      <dc-button (click)="apply()">{{ 'Apply' | translate }}</dc-button>
    </div>
  </div>
</div>
