import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { SymptomService } from '../../services/symptom.service';
@Component({
  selector: 'main [dc-symptom-filter]',
  templateUrl: './symptom-filter.component.html'
})
export class SymptomFilterComponent implements OnInit {
  @Input() filters: {
    region: any
  };

  regions: any[] = [
    {id: 'front-head', name: 'Front head'},
    {id: 'front-neck', name: 'Front neck'},
    {id: 'back-head', name: 'Back head'},
    {id: 'back-neck', name: 'Back neck'},
    {id: 'front-shoulder', name: 'Front shoulder'},
    {id: 'back-shoulder', name: 'Back shoulder'},
    {id: 'chest', name: 'Chest'},
    {id: 'abdomen', name: 'Abdomen'},
    {id: 'pelvis', name: 'Pelvis'},
    {id: 'back', name: 'Back'},
    {id: 'legs', name: 'Legs'},
    {id: 'unease', name: 'Unease'}
  ];


  showLoading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  apply() {
    this.activeModal.close(this.filters);
  }

  removeFilters() {
    this.filters = {
      region: null
    };
    this.activeModal.close(this.filters);
    console.log(this.filters);
  }

  close() {
    this.activeModal.dismiss();
  }
}
