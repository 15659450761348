import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs-compat/add/operator/do';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const headers = {};

    if (localStorage.getItem('jwt')) {
      headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');
    }

    request = request.clone({
      setHeaders: headers
    });

    return next.handle(request)
      .do(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {

          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            // TODO Handle invitation login error
            /*
            if (error.status === 401) {
              localStorage.removeItem('jwt');
              this.router.navigate(['/login']);
            }
            */
          }
        }
      );
  }
}
