import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCProgressBarComponent } from './progress-bar.component';
import { DCProgressContentComponent } from './progress-content.component';
import { ProgressBarService } from './progress-bar.service';



@NgModule({
  declarations: [
    DCProgressBarComponent,
    DCProgressContentComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    ProgressBarService
  ],
  exports: [
    DCProgressBarComponent,
    DCProgressContentComponent
  ]
})
export class DCProgressBarModule { }
