import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {BehaviorSubject} from 'rxjs';
import {UiContent} from '../classes/ui-content';

@Injectable({
  providedIn: 'root'
})
export class UiContentService {
  private currentUiContentSubject = new BehaviorSubject<UiContent>(null as UiContent);
  currentUiContent = this.currentUiContentSubject.asObservable();


  constructor(
    private apiService: ApiService
  ) { }

  getUiContents( query: any = {} ) {
    return this.apiService.get('ui_content', query);
  }

  getUiContent(uiContentId: any, query: any = {}) {
    return this.apiService.get(`ui_content/${uiContentId}`, query);
  }

  getLanguages(query: any = {}) {
    return this.apiService.get(`languages`, query);
  }

  updateUiContent(uiContentId: any, query: any = {}) {
    return this.apiService.put(`ui_content/${uiContentId}`, query);
  }

  createUiContent(uiContentId: any, query: any = {}) {
    return this.apiService.post(`ui_content`, query);
  }

  deleteUiContent(uiContentId: number) {
    return this.apiService.delete(`ui_content/${uiContentId}` );
  }
}
