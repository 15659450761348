import { Component, OnInit, ViewChild } from '@angular/core';
import { Institution } from '../../classes/institution';
import { InstitutionService } from '../../services/institution.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'main [dc-dashboard]',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  institutions: Institution[] = [];
  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 15
  };

  loadingInstitutions: boolean = false;

  constructor(
    private institutionService: InstitutionService
  ) {}

  ngOnInit() {
    this.getInstitutions();
  }


  getInstitutions(append: boolean = false) {
    if (append && this.pagination.total && this.institutions.length >= this.pagination.total) { return; }

    this.pagination.current_page = append ? this.pagination.current_page + 1 : 1;

    const params: any = {
      current_page: this.pagination.current_page,
      per_page: this.pagination.per_page
    };

    this.loadingInstitutions = !append;
    this.institutionService
      .getInstitutions(params)
      .subscribe(
        (response: { data: Institution[], total: number }) => {
          if (append) {
            this.institutions = this.institutions.concat(response.data);
          } else {
            this.perfectScrollbarDirectiveRef.scrollToTop();
            this.institutions = response.data;
            this.pagination.total = response.total;
          }
          this.loadingInstitutions = false;
        },
        (error) => {
          this.loadingInstitutions = false;
        }
      );
  }
}
