import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { InstitutionListComponent } from './pages/institution-list/institution-list.component';
import { InstitutionDetailsComponent } from './pages/institution-details/institution-details.component';
import { SymptomListComponent } from './pages/symptom-list/symptom-list.component';
import { SymptomDetailsComponent } from './pages/symptom-details/symptom-details.component';
import { SpecialtyListComponent } from './pages/specialty-list/specialty-list.component';
import { SpecialtyDetailsComponent } from './pages/specialty-details/specialty-details.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DCComponentModule } from '@dc/ui/components/component.module';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './pages/login/login.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ChangePasswordRequestComponent } from './pages/change-password-request/change-password-request.component';
import { DcDatePipe } from '@dc/ui/pipes/date.pipe';
import { AuthGuard } from './guards/auth.guard';
import { TokenInterceptor } from './services/auth.interceptor';
import { ProfileComponent } from './pages/profile/profile.component';
import { UiContentComponent } from './pages/ui-content/ui-content.component';
import { UiContentTranslateComponent } from './modals/ui-content-translate/ui-content-translate.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SymptomFilterComponent } from './modals/symptom-filter/symptom-filter.component';
import { InstitutionFilterComponent } from './modals/institution-filter/institution-filter.component';
import { AddCityComponent } from './modals/add-city/add-city.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChangePasswordComponent,
    ChangePasswordRequestComponent,
    ProfileComponent,
    DashboardComponent,
    InstitutionListComponent,
    InstitutionDetailsComponent,
    SymptomListComponent,
    SymptomDetailsComponent,
    SpecialtyListComponent,
    SpecialtyDetailsComponent,
    UiContentComponent,
    UiContentTranslateComponent,
    SettingsComponent,
    SymptomFilterComponent,
    InstitutionFilterComponent,
    AddCityComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    PerfectScrollbarModule,
    NgbModule,
    ModalModule.forRoot(),
    AppRoutingModule,
    DCComponentModule,
    ToastrModule.forRoot()
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    DcDatePipe],
   entryComponents: [
     UiContentTranslateComponent,
     SymptomFilterComponent,
     InstitutionFilterComponent,
     AddCityComponent
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
