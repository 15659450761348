import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Specialty } from '../../classes/specialty';
import { SpecialtyService } from '../../services/specialty.service';
import { Country } from '../../classes/country';
import { CountryService } from '../../services/country.service';
import { Institution } from '../../classes/institution';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { InstitutionService } from '../../services/institution.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@dc/ui/components/modal/modal.service';
import { Subscription } from 'rxjs';
import { User } from '../../classes/user';
import { AddCityComponent } from '../../modals/add-city/add-city.component';

@Component({
  selector: 'main[dc-institution-details]',
  templateUrl: './institution-details.component.html'
})
export class InstitutionDetailsComponent implements OnInit, OnDestroy {
  user: User = null;
  institutionForm: FormGroup;
  specialties: Specialty[] = [];
  countries: Country[] = [];
  institution: Institution = new Institution();
  institutionId: number;
  showLoading: boolean = false;
  savingInstitution: boolean = false;
  deletingInstitution: boolean = false;
  subscriptions: Subscription[] = [];
  cities: any[];
  showCity: boolean = false;

  // @HostListener('window:keydown', ['$event'])
  // keyEvent(event: KeyboardEvent) {
  //   if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
  //     this.save(false);
  //     event.preventDefault();
  //     return false;
  //   }
  // }

  constructor(
    private formBuilder: FormBuilder,
    private toasterService: DCToastService,
    private countryService: CountryService,
    private specialtyService: SpecialtyService,
    private institutionService: InstitutionService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.getSpecialties();
    this.getCountries();
    this.getInstitution();
    this.initForm();
    const routeSubscription = this.route.params.subscribe(
      (data) => {
        if (data.institutionId) {
          this.institutionId = +data.institutionId;
          this.getInstitution();
          this.getCities();
        }
      }
    );
    this.institutionForm.get('country_id').valueChanges
      .subscribe(
      (country_id) => {
        this.showCity = false;
        this.getCities(null, country_id);
        this.institutionForm.get('city_id').setValue(null);
        setTimeout(() => {
          this.showCity = true;
        }, 10)
      },
      () => {}
      );
    this.subscriptions.push(routeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  initForm() {
    this.institutionForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: [''],
      country_id: [null, [Validators.required]],
      city_id: ['', [Validators.required]],
      address: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone_1: ['', [Validators.required]],
      phone_2: [''],
      description: [''],
      latitude: [''],
      longitude: [''],
      logo_url: [''],
      parking_available: [false],
      wheelchair_accessible: [false],
      transportation_bus: [''],
      transportation_tram: [''],
      transportation_trolley: [''],
      specialty_ids: [null, [Validators.required]]
    });
  }


  showAddCityModal() {
    const selectedCountry =  this.institutionForm.get('country_id').value;
    const modalRef = this.modalService.open(AddCityComponent);
    modalRef.componentInstance.country_id = selectedCountry;
    modalRef.result
      .then(
      (result) => {
        if(result) {
          this.getCities();
        }
      }
    )
      .catch(() => {})}


  getCountries() {
    this.showLoading = true;
    this.countryService
      .getCountries()
      .subscribe(
        (response) => {
          this.countries = response.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toasterService.show({
            message: 'Failed to load countries.',
            type: 'error'
          });
        }
      );
  }

  getCities(search_term?: string, country_id?: number) {
    const params: any = {};
    if(search_term) {
      params.search_term = search_term;
    }
    if(country_id) {
      params.country_id = country_id;
    }
    this.institutionService
      .getCities(params)
      .subscribe(
        (response: any) => {
          this.cities = response.data;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to load cities.',
            type: 'error'
          });
        }
        );
  }

  getSpecialties() {
    this.showLoading = true;
    const params = {
      pagination: false
    };
    this.specialtyService.getSpecialties(params)
      .subscribe(
        (response) => {
          this.specialties = response.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toasterService.show({
            message: 'Failed to load specialties.',
            type: 'error'
          });
        }
      );
  }

  getInstitution() {
    if (this.institutionId) {
      this.showLoading = true;
      const params = {
        include: 'specialties'
      };
      this.institutionService.getInstitution(this.institutionId, params)
        .subscribe(
          (institution) => {
            this.institutionForm.patchValue(institution.data);
            this.institution = institution.data;
            this.showLoading = false;
          },
          () => {
            this.showLoading = false;
          }
        );
    }
  }



  dragEnd(marker) {
    const lngLat = marker.getLngLat();
    this.institution.latitude = lngLat.lat;
    this.institution.longitude = lngLat.lng;
  }


  save(close: boolean = false) {
    if (this.institutionForm.invalid) { return; }

    if (this.institutionId) {
      this.savingInstitution = true;
      this.institutionService
        .updateInstitution(this.institutionId, this.institutionForm.value)
        .subscribe(
          (response: { data: Institution }) => {
            this.toasterService.show({
              message: this.translate.instant('Institution was successfully updated.'),
              type: 'success'
            });

            this.institutionForm.patchValue(response.data);
            this.savingInstitution = false;
            if (close) { this.close(); }
          },
          () => {
            this.toasterService.show({
              message: this.translate.instant('There was an error while saving the institution.'),
              type: 'error'
            });
            this.savingInstitution = false;
          }
        );
    } else {
      this.savingInstitution = true;
      this.institutionService
        .createInstitution(this.institutionForm.value)
        .subscribe(
          (response: { data: Institution }) => {
            this.toasterService.show({
              message: this.translate.instant('Institution was successfully created.'),
              type: 'success'
            });

            this.institutionId = response.data.id;
            this.institutionForm.patchValue(response.data);
            this.savingInstitution = false;

            if (close) {
              this.close();
            } else {
              this.router.navigate([ './institution/' + this.institutionId + '/details']);
            }
          },
          () => {
            this.toasterService.show({
              message: this.translate.instant('There was an error while saving the institution.'),
              type: 'error'
            });
            this.savingInstitution = false;
          }
        );
    }
  }

  delete() {
    if (!this.institutionId) { return; }

    this.modalService.confirm({
      title: this.translate.instant('Delete'),
      body: this.translate.instant('Are you sure you want to delete this institution?'),
      size: 'small',
      buttons: [
        {
          text: this.translate.instant('No'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('Yes'),
          handler: () => {
            this.deletingInstitution = true;
            this.institutionService
              .deleteInstitution(this.institutionId)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: this.translate.instant('Institution succesfully deleted.'),
                    type: 'success'
                  });
                  this.deletingInstitution = false;
                  this.close();
                },
                () => {
                  this.toasterService.show({
                    message: this.translate.instant('Failed to delete the institution.'),
                    type: 'error'
                  });
                  this.deletingInstitution = false;
                }
              );
          }
        }
      ]
    });
  }

  close() {
    this.router.navigate(['/institution', 'list']);
  }
}
