import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dc-switch',
  templateUrl: './switch.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DCSwitchComponent),
      multi: true
    }
  ]
})
export class DCSwitchComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName: string;
  @Input() disabled: boolean = false;
  @Input() checked: boolean = false;
  @Input() label: string;
  @Input() value: boolean = false;
  @Input() name: string = null;
  @Input() platform: 'mobile' | 'website' = 'website';

  @Output() valueChange = new EventEmitter();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor() { }

  ngOnInit() {
    if (this.disabled !== false) { this.disabled = true; }
    if (this.checked !== false) { this.checked = true; this.value = true; }
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChangeStatus(event) {
    if (this.onChange) {
      this.onChange(this.value = event.target.checked);
    } else {
      this.value = event.target.checked;
    }
    this.valueChange.emit({ value: event.target.checked });
  }

}
