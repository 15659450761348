<section class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}">
  <div [dcLoading]="showLoading"></div>
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col sm-6">
        <h1>
          <div class="super-heading" translate>Specialty</div>
          <span>{{ specialty.name }}</span>
        </h1>
      </div>
      <div class="dc-col sm-6 flex padding-bottom" style="flex-direction: row; justify-content: flex-end;">
        <dc-button *ngIf="specialtyId" color="outline" icon="delete" size="small" [loading]="deletingSpecialty" (click)="delete()">{{ 'Delete specialty' | translate }}</dc-button>
    </div>

    </div>
    <form class="dc-row space-16" [formGroup]="specialtyForm">
      <div class="dc-col md-12">
        <div class="dc-row space-16">
          <div class="dc-col md-12">
            <h2 class="dc-text-color-primary" translate>Information</h2>
          </div>
          <div class="dc-col md-6">
            <dc-input
              label="{{ 'Specialty name' | translate }}"
              formControlName="name"
              [errorMessages]="{ required:'Required' | translate }"
            ></dc-input>
          </div>
          <div class="dc-col md-4">
            <dc-input
              label="{{ 'Color' | translate }}"
              formControlName="color"
              [errorMessages]="{ minlength:'Required minimum length is three characters' | translate }"
              [(ngModel)]="fixedSign"
              (keydown)="fixingSign($event)"
            ></dc-input>
          </div>
            <div class="dc-col md-2">
            <div class="color" [style.background]="colorValue"></div>
            </div>
        </div>
      </div>
    </form>
  </div>
</section>
<div class="dc-bottom-bar">
  <div class="dc-container flex" style="height: 100%; align-items: center;">
    <dc-button size="small" color="secondary" (click)="close()">{{ 'Close' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save(false)" [loading]="savingSpecialty" [disabled]="specialtyForm.invalid">{{ 'Save' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save(true)" [loading]="savingSpecialty" [disabled]="specialtyForm.invalid">{{ 'Save & Close' | translate }}</dc-button>
  </div>
</div>
