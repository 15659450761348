export class User {
  id: number;
  first_name: string;
  last_name: string;
  photo_url: string;
  email: string;
  password: string;

  doctor_id?: number;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
