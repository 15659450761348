import { Component, OnInit, ViewChild } from '@angular/core';
import { Institution } from '../../classes/institution';
import { InstitutionService } from '../../services/institution.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { User } from '../../classes/user';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@dc/ui/components/modal/modal.service';
import { InstitutionFilterComponent } from '../../modals/institution-filter/institution-filter.component';

@Component({
  selector: 'main [dc-institution-list]',
  templateUrl: './institution-list.component.html'
})
export class InstitutionListComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  user: User = null;
  institutions: Institution[] = [];
  searchTerm: string = '';
  sort: any = {
    field: 'id',
    direction: 'asc'
  };
  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 15
  };

  filters: {
    specialty_ids: any,
    country_id: any,
    city_id: any
  } = {
    specialty_ids: null,
    country_id: null,
    city_id: null
  };

  loadingInstitutions: boolean = false;


  constructor(
    private institutionService: InstitutionService,
    private router: Router,
    private modalService: ModalService,
    private route: ActivatedRoute
  ) { }
  ngOnInit() {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.specialty_ids) { this.filters.specialty_ids = queryParams.specialty_ids }
      if (queryParams.country_id) { this.filters.country_id = queryParams.country_id; }
      if (queryParams.city_id) { this.filters.city_id = queryParams.city_id; }
      this.getInstitutions();
    });
  }

  sortInstitutions(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }

    this.getInstitutions();
  }

  getInstitutions(append: boolean = false) {
    if (append && this.pagination.total && this.institutions.length >= this.pagination.total) { return; }

    this.pagination.current_page = append ? this.pagination.current_page + 1 : 1;

    const params: any = {
      current_page: this.pagination.current_page,
      per_page: this.pagination.per_page,
      sort: this.sort.field,
      direction: this.sort.direction
    };
    if (this.searchTerm.length > 0) {
      params.search_term = this.searchTerm;
    }
    Object.keys(this.filters).map((key) => {
      if (this.filters[key]) {
        params[key] = this.filters[key];
      }
    });
    this.loadingInstitutions = !append;
    this.institutionService
      .getInstitutions(params)
      .subscribe(
        (response: { data: Institution[], total: number }) => {
          if (append) {
            this.institutions = this.institutions.concat(response.data);
          } else {
            this.perfectScrollbarDirectiveRef.scrollToTop();
            this.institutions = response.data;
            this.pagination.total = response.total;
          }
          this.loadingInstitutions = false;
        },
        (error) => {
          this.loadingInstitutions = false;
        }
      );
  }

  //Filter

  showFilterModal() {
    this.checkFilters();
    const modalRef = this.modalService.open(InstitutionFilterComponent);
    modalRef.componentInstance.filters = this.filters;
    modalRef.result.then(
      (filters) => {
        this.filters = filters;
        if (this.filters.specialty_ids) { this.filters.specialty_ids = this.filters.specialty_ids.toString(); }
        this.router.navigate([], {queryParams: this.filters, queryParamsHandling: 'merge', relativeTo: this.route})
      },
      () => {}
    )

  }

  checkFilters() {
    if (this.route.snapshot.queryParams.specialty_ids) { this.filters.specialty_ids = JSON.parse("[" + this.route.snapshot.queryParams.specialty_ids + "]"); }
    if (this.route.snapshot.queryParams.country_id) { this.filters.country_id = this.route.snapshot.queryParams.country_id; }
    if (this.route.snapshot.queryParams.city_id) { this.filters.city_id = this.route.snapshot.queryParams.city_id; }
  }

}
