import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@dc/ui/components/modal/modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InstitutionService } from '../../services/institution.service';
import { TranslateService } from '@ngx-translate/core';
import { DCToastService } from '@dc/ui/components/toast/toast.service';

@Component({
  selector: 'main[dc-add-city]',
  templateUrl: './add-city.component.html'
})
export class AddCityComponent implements OnInit {
  @Input() country_id: number;
  name: string;
  showLoading: boolean = false;

  constructor(
    private modalService: ModalService,
    private activeModal: NgbActiveModal,
    private institutionService: InstitutionService,
    private translate: TranslateService,
    private toasterService: DCToastService
  ) { }

  ngOnInit(): void { }

  save() {
    const params = {
      country_id: this.country_id,
      name: this.name
    };
    this.institutionService.createCity(params)
      .subscribe(
        () => {
          this.toasterService.show({
            message: this.translate.instant('City was successfully created.'),
            type: 'success'
          });
          this.showLoading= false;
          this.activeModal.close(true);
        },
        () => {
          this.toasterService.show({
            message: this.translate.instant('There was an error while saving the city.'),
            type: 'error'
          });
          this.showLoading = false;
        }
        )
  }

  close() {
    this.activeModal.dismiss();
  }

}
