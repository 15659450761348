import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DCAccordionModule } from './accordion/accordion.module';
import { DCButtonModule } from './button/button.module';
import { DCCheckboxModule } from './checkbox/checkbox.module';
import { DCDatepickerModule } from './datepicker/datepicker.module';
import { DCInputModule } from './input/input.module';
import { DCLoadingModule } from './loading/loading.module';
import { DCModalModule } from './modal/modal.module';
import { DCProgressBarModule } from './progress-bar/progress-bar.module';
import { DCRadioModule } from './radio/radio.module';
import { DCSelectModule } from './select/select.module';
import { DCSwitchModule } from './switch/switch.module';
import { DCTabsModule } from './tabs/tabs.module';
import { DCFileModule } from './file/file.module';
import { DCSliderModule } from './carousel/slider.module';
import { DCToastModule } from './toast/toast.module';
import { DCTextEditorModule } from './text-editor/text-editor.module';
import { DCCollapseModule } from './collapse/collapse.module';
import { DCMapModule } from './map/map.module';
import { DCDropdownModule } from './dropdown/dropdown.module';
import { DCEmptyStateModule } from './empty-state/empty-state.module';
import { DCAvatarModule } from './avatar/avatar.module';
import { DCInfiniteScrollModule } from './infinite-scroll/infinite-scroll.module';
import { DcDatePipe } from '../pipes/date.pipe';
import { DCImageModule } from "./image/image.module";
import { DCTimepickerModule } from "@dc/ui/components/timepicker/timepicker.module";
import { DCOffClickModule } from "@dc/ui/components/off-click/off-click.module";
import { DCDatepickerMobileModule } from "@dc/ui/components/datepicker-mobile/datepicker-mobile.module";
import { DCAlertComponent } from "@dc/ui/components/modal/alert/alert.component";
import { DCConfirmComponent } from "@dc/ui/components/modal/confirm/confirm.component";
import { DCErrorComponent } from "@dc/ui/components/modal/error/error.component";
import { DCToastComponent } from "@dc/ui/components/toast/toast.component";
import { DCCallModule } from "@dc/ui/components/call/call.module";

@NgModule({
  declarations: [
    DcDatePipe
  ],
  entryComponents: [
    DCAlertComponent,
    DCConfirmComponent,
    DCErrorComponent,
    DCToastComponent
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    DCAccordionModule,
    DCButtonModule,
    DCCheckboxModule,
    DCDatepickerModule,
    DCFileModule,
    DCInputModule,
    DCLoadingModule,
    DCModalModule,
    DCProgressBarModule,
    DCRadioModule,
    DCSelectModule,
    DCSwitchModule,
    DCTabsModule,
    DCSliderModule,
    DCToastModule,
    DCTextEditorModule,
    DCCollapseModule,
    DCMapModule,
    DCDropdownModule,
    DCEmptyStateModule,
    DCAvatarModule,
    DCInfiniteScrollModule,
    DCImageModule,
    DCTimepickerModule,
    DCOffClickModule,
    DCDatepickerMobileModule,
    DCCallModule
  ],
  exports: [
    DCAccordionModule,
    DCButtonModule,
    DCCheckboxModule,
    DCDatepickerModule,
    DCFileModule,
    DCInputModule,
    DCLoadingModule,
    DCModalModule,
    DCProgressBarModule,
    DCRadioModule,
    DCSelectModule,
    DCSwitchModule,
    DCTabsModule,
    DCSliderModule,
    DCToastModule,
    DCTextEditorModule,
    DCCollapseModule,
    DCMapModule,
    DCDropdownModule,
    DCEmptyStateModule,
    DCAvatarModule,
    DCInfiniteScrollModule,
    DCImageModule,
    DcDatePipe,
    DCTimepickerModule,
    DCOffClickModule,
    DCDatepickerMobileModule,
    DCCallModule
  ],
  providers: [
    DcDatePipe
  ]
})
export class DCComponentModule { }
